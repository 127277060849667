define("discourse/plugins/swapd-auction/discourse/components/auction-category-chooser", ["exports", "select-kit/components/category-chooser", "discourse/models/category", "@ember/utils"], function (_exports, _categoryChooser, _category, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _categoryChooser.default.extend({
    pluginApiIdentifiers: ["auction-category-chooser"],
    categoriesByScope() {
      let scopedCategoryId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let categories = this.fixedCategoryPositionsOnCreate ? _category.default.list() : _category.default.listByActivity();
      categories = categories.filter(c => !(0, _utils.isBlank)(c.custom_fields.auction_enabled));
      if (scopedCategoryId) {
        const scopedCat = _category.default.findById(scopedCategoryId);
        scopedCategoryId = scopedCat.parent_category_id || scopedCat.id;
      }
      const excludeCategoryId = this.selectKit.options.excludeCategoryId;
      return categories.filter(category => {
        const categoryId = this.getValue(category);
        if (scopedCategoryId && categoryId !== scopedCategoryId && category.parent_category_id !== scopedCategoryId) {
          return false;
        }
        if (this.selectKit.options.allowSubCategories === false && category.parentCategory) {
          return false;
        }
        if (this.selectKit.options.allowUncategorized === false && category.isUncategorizedCategory || excludeCategoryId === categoryId) {
          return false;
        }
        const permissionType = this.selectKit.options.permissionType;
        if (permissionType && !this.allowRestrictedCategories) {
          return permissionType === category.permission;
        }
        return true;
      });
    }
  });
});