define("discourse/plugins/swapd-auction/discourse/initializers/swapd-auction", ["exports", "discourse/lib/plugin-api", "discourse/widgets/component-connector", "I18n", "discourse/widgets/raw-html", "discourse/lib/formatter", "discourse/widgets/post", "discourse-common/lib/icon-library", "virtual-dom", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/lib/render-tag", "@ember/template", "@ember/application"], function (_exports, _pluginApi, _componentConnector, _I18n, _rawHtml, _formatter, _post, _iconLibrary, _virtualDom, _ajax, _ajaxError, _decorators, _renderTag, _template, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function bidHtml(createdAt, price, username) {
    const dt = new Date(createdAt);
    const when = (0, _formatter.autoUpdatingRelativeAge)(dt, {
      format: "medium-with-ago"
    });
    return (0, _template.htmlSafe)(_I18n.default.t("auction.bid_post", {
      username,
      price,
      when
    }));
  }
  function initPlugin(api) {
    var _dec, _obj;
    api.replaceIcon("notification.auction.notification", "gavel");
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.swapd_auction_enabled) {
      return;
    }
    api.includePostAttributes("is_auction", "auction_end_date", "auction_current_bid", "auction_top_bidder", "auction_reserve_price", "auction_buy_now_price", "auction_can_bid", "auction_bid_price", "auction_can_delete_bid", "auction_mod", "auction_last_post", "auction_your_max_bid", "is_auction_closed");
    api.decorateWidget("post-contents:after-cooked", dec => {
      if (!dec.attrs.is_auction) {
        return;
      }
      const auction = new _componentConnector.default(dec.widget, "post-auction", {
        current_bid: dec.attrs.auction_current_bid,
        top_bidder: dec.attrs.auction_top_bidder,
        end_date: dec.attrs.auction_end_date,
        reserve_price: dec.attrs.auction_reserve_price,
        buy_now_price: dec.attrs.auction_buy_now_price,
        can_bid: dec.attrs.auction_can_bid,
        post_id: dec.attrs.id,
        last_post: dec.attrs.auction_last_post,
        your_max_bid: dec.attrs.auction_your_max_bid,
        auction_closed: dec.attrs.is_auction_closed
      }, ["current_bid", "top_bidder", "can_bid", "last_post", "your_max_bid", "auction_closed"]);
      return auction;
    });
    api.reopenWidget("post-small-action", {
      buildClasses(attrs) {
        const classes = this._super(...arguments);
        if (attrs.actionCode != "bid") {
          return classes;
        }
        return classes + " post-bid";
      },
      deleteBid() {
        const self = this;
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.confirm({
          message: _I18n.default.t("auction.confirm_delete_bid"),
          didConfirm: () => {
            self._deleteBid();
          }
        });
      },
      _deleteBid() {
        (0, _ajax.ajax)(`/auction/${this.attrs.id}`, {
          type: "DELETE"
        }).then(result => {
          if (result.e) {
            const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
            dialog.alert({
              message: result.e
            });
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      html(attrs) {
        if (attrs.actionCode != "bid") {
          return this._super(...arguments);
        }
        const contents = [];
        if (attrs.auction_can_delete_bid) {
          contents.push(this.attach("button", {
            className: "small-action-delete",
            icon: "trash-alt",
            action: "deleteBid",
            title: "post.controls.delete"
          }));
        }
        contents.push(_post.avatarFor.call(this, "small", {
          template: attrs.avatar_template,
          username: attrs.username,
          url: attrs.usernameUrl
        }));
        const description = bidHtml(new Date(attrs.created_at), attrs.auction_bid_price, attrs.username);
        contents.push(new _rawHtml.default({
          html: `<p>${description}</p>`
        }));
        return [(0, _virtualDom.h)("div.topic-avatar", (0, _iconLibrary.iconNode)("gavel")), (0, _virtualDom.h)("div.small-action-desc", contents)];
      }
    });
    api.modifyClass("model:composer", (_dec = (0, _decorators.default)("siteSettings.custom_composer_fields_enabled", "category.custom_composer_fields_enabled", "category.custom_composer_fields", "topicFirstPost", "action", "topic.is_auction"), (_obj = {
      pluginId: "swapd-auction",
      categoryCustomComposerFields(setting_custom_composer_fields_enabled, custom_composer_fields_enabled, custom_composer_fields, topicFirstPost, action, isAuction) {
        if (!topicFirstPost) return;
        if (!setting_custom_composer_fields_enabled) return;
        if (action == "edit" && isAuction) {
          return this.site.swapd_auction_fields;
        }
        if (!custom_composer_fields_enabled) return;
        return custom_composer_fields;
      }
    }, (_applyDecoratedDescriptor(_obj, "categoryCustomComposerFields", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryCustomComposerFields"), _obj)), _obj)));
    (0, _renderTag.replaceTagRenderer)((tag, params) => {
      let def = (0, _renderTag.defaultRenderTag)(tag, params);
      if (tag.toLowerCase() == "auction") {
        let html = (0, _iconLibrary.iconHTML)("gavel");
        def = def.replace(/>auction/i, `>${html} auction`);
      }
      return def;
    });
  }
  var _default = _exports.default = {
    name: "swapd-auction",
    after: "extend-for-composer-custom-fields",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.8", initPlugin);
    }
  };
});